<template>
    <div class="tw-mt-10">
        <ProfileHeader />
        <SettingsMenu />
    </div>
</template>

<script>
import ProfileHeader from "@/components-prism/account/ProfileHeader.vue";
import SettingsMenu from "@/components-prism/account/SettingsMenu.vue";

export default {
    components: {
        ProfileHeader,
        SettingsMenu
    },
};
</script>

<style>
</style>