<template>
    <div class="tw-bg-white sm:tw-shadow tw-overflow-hidden sm:tw-rounded-md">
  <ul class="tw-divide-y tw-divide-gray-200" style="margin-bottom: 0"> 
    <SettingsMenuItem 
        title="Account"
        description="Edit your account details"
        :to="{name: 'Profile'}"
        :icon="['fal', 'user']"
    />
    <SettingsMenuItem 
        title="Password & security"
        description="Change your password"
        :to="{name: 'Security'}"
        :icon="['fal', 'shield-alt']"
    />
    <SettingsMenuItem 
        title="Notifications"
        description="Subscribe to specific tags"
        :to="{name: 'Notifications'}"
        :icon="['fal', 'bell']"
    />
    <SettingsMenuItem 
        title="Send feedback"
        description="Help us improve risky.live"
        :to="{name: 'Submit feedback'}"
        :icon="['fal', 'bullhorn']"
    />
    <SettingsMenuItem 
        title="Help & support"
        description="Having issues? Let us know!"
        :to="{name: 'Help & support'}"
        :icon="['fal', 'question-circle']"
    />
    <SettingsMenuItem 
        title="Switch team"
        :to="{name: 'Switch team'}"
        :icon="['fal', 'exchange-alt']"
    />
    <SettingsMenuItem 
        title="Sign out"
        :to="{name: 'Logout'}"
        :icon="['fal', 'sign-out']"
        textColor="tw-text-red-500"
    />
  </ul>
</div>
</template>

<script>
import SettingsMenuItem from './SettingsMenuItem.vue'

export default {
    components: { SettingsMenuItem },
    
}
</script>