<template>
  <li>
      <router-link :to="to" class="tw-block hover:tw-bg-gray-50">
        <div class="tw-flex tw-items-center tw-px-4 tw-py-2 sm:tw-px-6">
            <div class="tw-flex-shrink-0">
                <icon :icon="icon" style="font-size: 1.2rem" class="tw-h-12 tw-w-12" v-bind:class="textColor !== null ? textColor : 'tw-text-gray-400'" fixed-width/>
            </div>
          <div class="tw-min-w-0 tw-flex-1 tw-flex tw-items-center">
            <div class="tw-min-w-0 tw-flex-1 tw-px-4 md:tw-grid md:tw-grid-cols-2 md:tw-gap-4">
              <div>
                <p class="tw-text tw-font-medium tw-truncate" v-bind:class="textColor ? textColor : 'tw-text-gray-800'" style="margin-bottom: 0">{{ title }}</p>
                <p v-if="description" class="tw-text-xs tw-font-normal tw-text-gray-400 tw-truncate" style="margin-bottom: 0">{{ description }}</p>
              </div>
            </div>
          </div>
          <div>
            <!-- Heroicon name: solid/chevron-right -->
            <svg class="tw-h-5 tw-w-5" v-bind:class="textColor ? textColor : 'tw-text-gray-400'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
            </svg>
          </div>
        </div>
      </router-link>
    </li>
</template>

<script>
export default {
    props: ["title", "description", "to", "icon", "textColor"]
}
</script>

<style>

</style>